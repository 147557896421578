import React, { FC } from 'react';
import { Button, Flex } from 'antd';
import style from './TopForecasters.module.scss';
import { Clickable, Container, DisplayDecimal, DisplayText, UserAvatar } from '../../../components';
import { UserWithRoi } from '../../../models';
import { useNavigate } from 'react-router-dom';

interface Props {
  users: UserWithRoi[];
}

export const TopForecasters: FC<Props> = ({ users }) => {
  const navigate = useNavigate();

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical gap={15}>
        {users.map(u => (
          <Flex key={u.id} justify="space-between" align="center">
            <Clickable onClick={() => navigate(`/users/${u.id}`)}>
              <Flex>
                <UserAvatar size={40} />
                <Flex vertical>
                  <DisplayText value={u.id} size="small" weight="medium" />
                  <Flex gap={5}>
                    <DisplayText value="ROI" size="small" weight="medium" />
                    <DisplayDecimal value={u.roi} size="small" weight="medium" />
                  </Flex>
                </Flex>
              </Flex>
            </Clickable>
            <Button type="primary" className={style.ButtonFollow}>
              Follow
            </Button>
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};
