import { useParams } from 'react-router-dom';
import { useBetsApi, useLeaguesApi, useUsersApi } from '../../api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export function useProfileService() {
  const { getProfile, getUsers } = useUsersApi();
  const { getLeagues } = useLeaguesApi();
  const { id } = useParams();
  const { getBets } = useBetsApi();

  const userStatsResult = useQuery({
    queryKey: ['userStats', id],
    queryFn: () => getProfile(id),
  });

  const usersResult = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
  });

  const leagues = useQuery({
    queryKey: ['leagues'],
    queryFn: () => getLeagues(true),
  });

  const userBets = useQuery({
    queryKey: ['userBets', id],
    queryFn: () => getBets(0, [], id),
  });

  const monthTop = useMemo(() => usersResult.data?.month.slice(0, 3) ?? [], [usersResult]);
  const annualTop = useMemo(() => usersResult.data?.allTime.slice(0, 3) ?? [], [usersResult]);

  return {
    id,
    userStats: userStatsResult.data,
    leagues: leagues.data,
    userBets: userBets.data,
    monthTop,
    annualTop,
    loading: userStatsResult.isFetching || usersResult.isFetching || leagues.isFetching || userBets.isFetching,
  };
}
