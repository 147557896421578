import React, { FC } from 'react';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import { Container, DisplayDecimal, DisplayPercentage, DisplayText, Table } from '../../../components';
import { OddRangeStatistics } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import { styles } from '../../../styles/styles';

export const OddRangePerformance: FC = () => {
  const { userStats } = useProfileService();
  const data = userStats.oddRangeBetStats.filter(x => x.stats.lost + x.stats.won > 0);

  const columns: ColumnsType<OddRangeStatistics> = [
    {
      key: 'oddRange',
      title: 'Odd',
      align: 'center',
      render: (row: OddRangeStatistics) => (
        <DisplayText value={`${row.from} - ${row.to}`} weight="medium" />
      ),
    },
    {
      key: 'yield',
      title: 'Yield',
      align: 'center',
      render: (row: OddRangeStatistics) => (
        <DisplayPercentage
          value={row.stats.yield}
          weight="medium"
          color={row.stats.yield >= 0 ? styles.primary : styles.red}
          textAlign='center'
        />
      ),
    },
    {
      key: 'bets',
      title: 'Bets',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: OddRangeStatistics) => (
        <DisplayText value={won + pending + lost} weight="medium" textAlign='center' />
      ),
    },
    {
      key: 'wld',
      title: 'W/L/D',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: OddRangeStatistics) => (
        <Flex align="center" justify="center">
          <DisplayText value={`${won}/`} weight="medium" color={styles.primary} />
          <DisplayText value={`${pending}/`} weight="medium" color={styles.yellow} />
          <DisplayText value={lost} weight="medium" color={styles.red} />
        </Flex>
      ),
    },
    {
      key: 'averageOdd',
      title: 'Average Odd',
      align: 'center',
      render: ({ stats: { averageOdd } }: OddRangeStatistics) => 
        <DisplayDecimal value={averageOdd} weight="medium" textAlign='center' />,
    },
    {
      key: 'averageBet',
      title: 'Average Bet',
      align: 'center',
      render: ({ stats: { averageBet } }: OddRangeStatistics) => 
        <DisplayDecimal value={averageBet} weight="medium" textAlign='center' showCurrency />,
    },
    {
      key: 'roi',
      title: 'ROI',
      align: 'center',
      render: ({ stats: { roi } }: OddRangeStatistics) => (
        <DisplayDecimal 
          value={roi}
          weight="medium"
          color={roi >= 0 ? styles.primary : styles.red}
          textAlign='center'
          showCurrency
        />
      ),
    },
    {
      key: 'passage',
      title: 'Passage',
      align: 'center',
      render: ({ stats: { won, lost } }: OddRangeStatistics) => (
        <DisplayDecimal value={won / (won + lost)} weight="medium" textAlign='center' />
      ),
    },
  ];

  return (
    <Container bordered innerSpace={20}>
      <Table
        noDataText="No Matches"
        showHeader
        pagination={false}
        columns={columns}
        data={data}
        rowKey={(row: OddRangeStatistics) => row.from}
      />
    </Container>
  );
};
