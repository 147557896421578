import { styles } from '../styles/styles';

export const fontWeights = {
  small: styles.fontWeightSmall,
  normal: styles.fontWeightNormal,
  medium: styles.fontWeightMedium,
  'semi-bold': styles.fontWeightSemiBold,
};

export type FontWeights = 'small' | 'normal' | 'medium' | 'semi-bold';
