import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { create } from 'zustand';

export const cognitoUserPool = new CognitoUserPool({
  UserPoolId: 'eu-central-1_eBnIbZYoJ',
  ClientId: 'ebastl7mrt2r0fi3r20bqr0j4',
});

interface Store {
  username?: string;
  currentUser?: CognitoUser;
  setUsername(username: string): void;
  setCurrentUser(): void;
}

const useStore = create<Store>()(set => ({
  username: undefined,
  currentUser: cognitoUserPool.getCurrentUser(),
  setUsername: username => set(state => ({ ...state, username })),
  setCurrentUser: () =>
    set(state => ({
      ...state,
      currentUser: cognitoUserPool.getCurrentUser(),
    })),
}));

export function useCognito() {
  const store = useStore();
  return {
    currentUser: store.currentUser,
    setCurrentUser: store.setCurrentUser,
    username: store.username,
    setUsername: store.setUsername,
  };
}
