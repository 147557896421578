import React, { FC } from 'react';
import { Avatar, Col, Flex, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Prediction } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { Clickable, DisplayText, Container, Space } from '../../../components';
import { styles } from '../../../styles/styles';
import { styled } from 'styled-components';

interface Props {
  prediction: Prediction;
  size: string;
  onCreateBet: (prediction: Prediction) => void;
}

const RecommendedBet: FC<Props> = ({ size, prediction, onCreateBet }) => {
  const navigate = useNavigate();
  const redirectToMatchDetails = () => navigate(prediction.match.id);

  return (
    <Container bordered width={260} innerSpace={15}>
      <Flex vertical gap={15}>
        <Flex justify="space-between">
          <Flex vertical align="center">
            <DisplayText size="normal" value={dayjs(prediction.match.scheduledAt).format('HH:mm')} />
            <DisplayText value={dayjs(prediction.match.scheduledAt).format('DD MMM')} />
          </Flex>
          <Flex vertical align="center">
            <DisplayText color={styles.red} size="normal" value="+25" />
            <DisplayText color={styles.red} value="forecasts" />
          </Flex>
        </Flex>

        <Clickable onClick={redirectToMatchDetails}>
          <Flex justify="center" align="center" vertical>
            <CircledIcon logo={prediction.match.countryFlag} size={55} />
            <DisplayText value={prediction.match.league} />
          </Flex>

          <Space size={10} />

          <Container height={130}>
            <Row>
              <Col span={10}>
                <CircledIcon logo={prediction.match.homeTeamLogo} size={70} />
              </Col>
              <Col span={4} flex="stretch">
                <FullHeightFlex justify="center" align="center">
                  <VersusIcon />
                </FullHeightFlex>
              </Col>
              <Col span={10}>
                <CircledIcon logo={prediction.match.awayTeamLogo} size={70} />
              </Col>
            </Row>

            <Row>
              <Col span={10}>
                <DisplayText value={prediction.match.homeTeam} textAlign='center' />
              </Col>
              <Col span={10} offset={4}>
                <DisplayText value={prediction.match.awayTeam} textAlign='center' />
              </Col>
            </Row>
          </Container>
        </Clickable>

        <Clickable onClick={() => onCreateBet(prediction)}>
          <Container bordered innerSpace={15}>
            <Flex vertical justify="center">
              <Flex justify="center" gap={5}>
                <DisplayText size="normal" value={prediction.token} />
                <DisplayText size="normal" color={styles.primary} value={`${prediction.value}%`} weight="semi-bold" />
              </Flex>
              <Flex justify="center">
                <OddValueContainer>
                  <DisplayText size="normal" value={prediction.odd} weight="semi-bold" />
                </OddValueContainer>
              </Flex>
            </Flex>
          </Container>
        </Clickable>
      </Flex>
    </Container>
  );
};

interface CircledIconProps {
  logo: string;
  size: number;
}

const CircledIcon: FC<CircledIconProps> = ({ logo, size }) => (
  <Flex vertical align="center">
    <CircledBorder $size={size}>
      <Avatar src={logo} size={size * 0.7} />
    </CircledBorder>
  </Flex>
);

const VersusIcon = styled(CloseOutlined)`
  display: block;
  width: 20px;
  height: 20px;
`;

const FullHeightFlex = styled(Flex)`
  height: 100%;
`;

const CircledBorder = styled.div<{ $size: number }>`
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${styles.greyDark};
  border-radius: 50%;
  margin-bottom: 5px;
`;

const OddValueContainer = styled.div`
  background-color: ${styles.grey};
  border-radius: 4px;
  padding: 2px 5px;
`;

export default RecommendedBet;
