import React, { FC } from 'react';
import { DisplayText, Table, Container } from '../../../components';
import { Position, Team } from '../../../models';
import { useMatchDetailsService } from '../MatchDetailsService';
import { ColumnsType } from 'antd/es/table';
import { styles } from '../../../styles/styles';

const columns: ColumnsType<Position> = [
  {
    key: 'id',
    title: '#',
    dataIndex: 'id',
    align: 'center',
    render: (id: string) => <DisplayText value={id} textAlign='center' />,
  },
  {
    key: 'team',
    title: 'Team',
    dataIndex: 'team',
    width: 270,
    align: 'center',
    render: (team: Team) => <DisplayText value={team.name} textAlign='center' />,
  },
  {
    key: 'matches',
    title: 'M',
    dataIndex: 'games',
    align: 'center',
    render: (games: number) => <DisplayText value={games} textAlign='center' />,
  },
  {
    key: 'wins',
    title: 'W',
    dataIndex: 'wons',
    align: 'center',
    render: (wons: number) => <DisplayText value={wons} color={styles.primary} weight="medium" textAlign='center' />,
  },
  {
    key: 'draws',
    title: 'D',
    dataIndex: 'deals',
    align: 'center',
    render: (deals: number) => <DisplayText value={deals} color={styles.yellow} weight="medium" textAlign='center' />,
  },
  {
    key: 'loses',
    title: 'L',
    dataIndex: 'loses',
    align: 'center',
    render: (loses: number) => <DisplayText value={loses} color={styles.red} weight="medium" textAlign='center' />,
  },
  {
    key: 'goals',
    title: 'G',
    align: 'center',
    render: (row: Position) => <DisplayText value={`${row.scoredGoals}/${row.missedGoals}`} textAlign='center' />,
  },
  {
    key: 'points',
    title: 'P',
    dataIndex: 'points',
    align: 'center',
    render: (points: number) => <DisplayText value={points} textAlign='center' />,
  },
];

export const FullTournament: FC = () => {
  const { match } = useMatchDetailsService();

  return (
    <Container bordered innerSpace={20}>
      <Table
        showHeader
        pagination={false}
        rowHighlight={(p: Position) => p.teamId === match.match.home.id || p.teamId === match.match.away.id}
        columns={columns}
        data={match.tournament.positions}
        rowKey={'id'}
        cellPadding={8}
      />
    </Container>
  );
};
