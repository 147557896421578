import React, { FC } from 'react';
import { Flex } from 'antd';
import { RecommendedBet } from './index';
import { Prediction } from '../../../models';
import { DisplayText, Space, useCreateBetModalService } from '../../../components';
import { useMatchesListService } from '../MatchesListService';

const RecommendedBets: FC = () => {
  const { recommended } = useMatchesListService();
  const { show } = useCreateBetModalService();

  const onCreateBet = (prediction: Prediction) => {
    show(prediction.token, prediction.match.id);
    window.scrollTo(0, 0);
  };

  if (!recommended.length) return <></>;

  return (
    <Flex vertical align="center">
      <DisplayText value="Recommended bets" size="title" />
      <Space size={20} />
      <Flex justify="space-between" gap={25}>
        {recommended.length >= 4 && (
          <RecommendedBet size="Small" prediction={recommended[3]} onCreateBet={onCreateBet} />
        )}
        {recommended.length >= 2 && (
          <RecommendedBet size="Medium" prediction={recommended[1]} onCreateBet={onCreateBet} />
        )}
        {recommended.length >= 1 && (
          <RecommendedBet size="Large" prediction={recommended[0]} onCreateBet={onCreateBet} />
        )}
        {recommended.length >= 3 && (
          <RecommendedBet size="Medium" prediction={recommended[2]} onCreateBet={onCreateBet} />
        )}
        {recommended.length === 5 && (
          <RecommendedBet size="Small" prediction={recommended[4]} onCreateBet={onCreateBet} />
        )}
      </Flex>
    </Flex>
  );
};

export default RecommendedBets;
