import React from 'react';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useLoginModalService } from '../components';
import { useCognito } from '../auth';
import toast from 'react-hot-toast';

const apiEndpoint = process.env.REACT_APP_API_URL;

type Dictionary = { [propKey: string]: string };

export const formItemErrorsMap: Dictionary = {
  UserNotFoundException: 'username',
  NotAuthorizedException: 'password',
  UserNotConfirmedException: 'username',
  UsernameAlreadyExist: 'username',
  UserEmailAlreadyExist: 'email',
};

export function useApi() {
  const { currentUser } = useCognito();
  const { show } = useLoginModalService();

  const getSession = (user: CognitoUser): Promise<CognitoUserSession | null> => {
    return new Promise<CognitoUserSession | null>(resolve => {
      if (!user) {
        resolve(null);
        return;
      }

      user.getSession((error, session) => {
        resolve(session);
      });
    });
  };

  const api = React.useCallback(
    async <TRequest, TResponse>(
      method: 'GET' | 'POST' | 'PUT' | 'DELETE',
      path: string,
      params: { [key: string]: any } | null = null,
      body: TRequest | null = null,
    ): Promise<TResponse> => {
      let query = '';
      if (params) {
        query = `?${Object.entries(params)
          .filter(([_, value]) => value && (!Array.isArray(value) || value.length > 0))
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`;
      }

      const session = await getSession(currentUser);
      let accessToken = session?.getAccessToken()?.getJwtToken();
      try {
        const response = await fetch(`${apiEndpoint}/${path}${query}`, {
          method,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: body ? JSON.stringify(body) : null,
        });

        if (!response.ok) {
          throw response;
        }

        return await response.json();
      } catch (error) {
        if (error && error.status === 401) {
          show();
          throw new Error('User is not authorized');
        }

        if (error && error.status === 500) {
          throw new Error('Service is not available. Please try again later.');
        }

        var json = await error.json();
        toast.error(json?.detail ?? json?.title ?? json ?? error);
      }
    },
    [currentUser, show],
  );

  return { api };
}
