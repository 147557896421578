export const colors = {
  background: '#FFFFFF',
  white: '#FFFFFF',
  whiteHover: '#EBEBEB',
  primary: '#00BE8C',
  primaryHover: '#00956D',
  primaryDark: '#00261C',
  secondary: '#D3F7E8',
  secondaryDark: '#36524a',
  red: '#EA274B',
  yellow: '#FFD97D',
  green: '#00BE8C',
};
