import { FC } from 'react';
import { Injury, InjuryStatus } from '../../../../models';
import { styles } from '../../../../styles/styles';
import { Flex } from 'antd';
import { DisplayText } from '../../../../components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

interface Props {
  injury?: Injury;
}

interface DisplayInjuryProps {
  color: string;
  icon: IconDefinition;
  reason: string;
}

export const DisplayInjury: FC<Props> = ({ injury }) => {
  const inStatus: DisplayInjuryProps = {
    color: styles.primary,
    icon: faCircleCheck,
    reason: 'IN',
  };

  const missingStatus: DisplayInjuryProps = {
    color: styles.red,
    icon: faCircleXmark,
    reason: injury?.reason,
  };

  const questionableStatus: DisplayInjuryProps = {
    color: styles.yellow,
    icon: faCircleQuestion,
    reason: injury?.reason,
  };

  let obj = inStatus;
  if (injury && injury.status === InjuryStatus.Missing) {
    obj = missingStatus;
  } else if (injury && injury.status === InjuryStatus.Questionable) {
    obj = questionableStatus;
  }

  return (
    <Flex gap={5} align="center">
      <FontAwesomeIcon icon={obj.icon} size="xl" style={{ color: obj.color }} />
      <DisplayText value={obj.reason} weight="medium" color={obj.color} overflow textAlign="center" fullWidth />
    </Flex>
  );
};
