import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayText } from '../../../components';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import { BetsBarChart } from './BetsBarChart';

export const BetsStatistics: FC = () => {
  const { userStats } = useProfileService();
  const { won, pending, lost, averageOdd, maxOdd } = 
    userStats.monthBetStats.length > 0 
      ? userStats.monthBetStats[0].stats 
      : { won: 0, pending: 0, lost: 0, averageOdd: 0, maxOdd: 0 };
  const count = won + pending + lost;
  return (
    <Flex>
      <Container width={400} height={210} bordered innerSpace={20}>
        <DisplayText weight="medium" size="section-title" value={`${count} Bet(s)`} />
        <BetsBarChart />

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Average Coefficient" />
          <DisplayDecimal weight="medium" size="normal" value={averageOdd} />
        </Flex>

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Coefficient Played" />
          <DisplayDecimal weight="medium" size="normal" value={maxOdd} />
        </Flex>
      </Container>
    </Flex>
  );
};
