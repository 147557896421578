import { useLeaguesApi, useMatchesApi } from '../../api';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useMatchesListService } from '../Matches';

export function useMatchDetailsService() {
  const queryClient = useQueryClient();
  const { getMatch, updateMatchVote, getMatches, getPlayers } = useMatchesApi();
  const { getLeagueBasicStats } = useLeaguesApi();
  const { id } = useParams();
  const { config } = useMatchesListService();

  const match = useQuery({
    queryKey: ['match', id],
    queryFn: () => getMatch(id),
  });

  const leagueId = useMemo(() => match.data?.match.leagueId, [match]);

  const leagueBasicStats = useQuery({
    queryKey: ['leagueBasicStats', id],
    queryFn: () => getLeagueBasicStats(leagueId!),
    enabled: !!leagueId,
  });

  const matches = useQuery({
    queryKey: ['otherMatches', config.date, leagueId],
    queryFn: () => getMatches(config.leagueIds, config.date, 7),
    enabled: !!leagueId,
  });

  const myVote = useMemo(() => localStorage.getItem(`${id}_matchvote`), [id]);

  const voteResponse = useMutation({
    mutationFn: (type: string) => updateMatchVote(id, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['match', id],
      });
    },
  });

  const vote = (type: string) => {
    voteResponse.mutate(type);
    localStorage.setItem(`${id}_matchvote`, type);
  };

  const matchPlayers = useQuery({
    queryKey: ['matchPlayers', id],
    queryFn: () => getPlayers(id),
  });

  return {
    match: match.data,
    loading: match.isFetching || leagueBasicStats.isFetching || matches.isFetching || matchPlayers.isFetching,
    leagueBasicStats: leagueBasicStats.data,
    matchesList: matches.data,
    matchPlayers: matchPlayers.data,
    vote,
    myVote,
  };
}
