import React, { FC } from 'react';
import { Avatar, Flex } from 'antd';
import dayjs from 'dayjs';
import Countdown from 'react-countdown';
import { HeadToHeadMatch, Match } from '../../../models';
import { Container, DisplayDate, DisplayText, ResultBadge, Space } from '../../../components';
import { styled } from 'styled-components';
import image from '../../../assets/img/matchPreviewBackground.png';
import { styles } from '../../../styles/styles';
import { fontSizes, fontWeights } from '../../../utils';

interface MatchHeaderProps {
  match: Match;
  homeTeamMatches: HeadToHeadMatch[];
  awayTeamMatches: HeadToHeadMatch[];
}

const MatchHeader: FC<MatchHeaderProps> = ({ match, homeTeamMatches, awayTeamMatches }) => {
  const homeMatches = homeTeamMatches.slice(0, 5).reverse();
  const homeTeam = match.home;
  const awayMatches = awayTeamMatches.slice(0, 5);
  const awayTeam = match.away;

  const notStarted = dayjs(match.scheduledAt) > dayjs();
  const inProgress = dayjs(match.scheduledAt) <= dayjs() && match.homeGoals === null && match.awayGoals === null;
  const finished = match.homeGoals !== null && match.awayGoals !== null;

  return (
    <ImageBackground>
      <Container width={1400}>
        <Flex vertical align="center">
          <Avatar size={50} src={match.countryFlag} />
          <DisplayText value={match.league.toUpperCase()} size="title" weight="semi-bold" color="white" />
        </Flex>
        `
        <Flex justify="space-between">
          <Flex vertical>
            {notStarted && <DisplayText value="Will Start In" size="title" weight="semi-bold" color="white" />}
            {inProgress && (
              <DisplayText value="The Match Is Taking Place" size="title" weight="semi-bold" color="white" />
            )}
            {finished && <DisplayText value="Finished" size="title" weight="semi-bold" color="white" />}
            {notStarted && <StyledCountdown date={String(match.scheduledAt)} />}
            {finished && (
              <DisplayText
                value={`${match.homeGoals} - ${match.awayGoals}`}
                size="title"
                weight="semi-bold"
                color="white"
                textAlign='center'
              />
            )}
          </Flex>

          <Flex vertical align="center">
            <DisplayDate
              date={match.scheduledAt}
              showDateOnly
              showFullDate
              size="title"
              weight="semi-bold"
              color="white"
            />
            <DisplayDate date={match.scheduledAt} showTimeOnly size="title" weight="semi-bold" color="white" />
          </Flex>
        </Flex>
        <Space size={200} />
        <Flex justify="space-between">
          <Flex gap={10}>
            <Avatar size={50} src={homeTeam.logoUrl} />
            <Flex vertical>
              <DisplayText value={homeTeam.name.toUpperCase()} size="title" weight="semi-bold" color="white" />
              <Flex gap={5}>
                {homeMatches.map(m => (
                  <ResultBadge match={m} team={homeTeam} size={10} showLetter={false} />
                ))}
              </Flex>
            </Flex>
          </Flex>

          <Flex gap={10}>
            <Flex vertical align="flex-end">
              <DisplayText value={awayTeam.name.toUpperCase()} size="title" weight="semi-bold" color="white" />
              <Flex gap={5}>
                {awayMatches.map(m => (
                  <ResultBadge match={m} team={awayTeam} size={10} showLetter={false} />
                ))}
              </Flex>
            </Flex>
            <Avatar size={50} src={awayTeam.logoUrl} />
          </Flex>
        </Flex>
      </Container>
    </ImageBackground>
  );
};

const ImageBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 38, 28, 0.6);
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StyledCountdown = styled(Countdown)`
  color: ${styles.white};
  font-size: ${fontSizes.title};
  font-weight: ${fontWeights['semi-bold']};
`;

export default MatchHeader;
