import React, { FC } from 'react';
import { Modal, Image, Form, Avatar, Input, InputNumber, Button } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PredictionLabel, useCreateBetModalService } from '../../components';

export const CreateBetModal: FC = () => {
  const { display, token, match, hide, createBet, loading } = useCreateBetModalService();
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    hide();
  };

  const onSubmit = async ({ amount, description }: any) => {
    await createBet(match.id, token, amount, description);
    form.resetFields();
    hide();
  };

  if (loading) return <></>;

  const prediction = match?.predictions.find(x => x.token === token);
  return (
    <Modal open={display} onCancel={onClose} closable={true} centered={true} footer={null}>
      <Title>Place Bet</Title>
      <CenteredItemsContainer>
        <Text>
          <Flag width={20} src={match?.countryFlag} preview={false} />
          <span>{match?.league}</span>
        </Text>
      </CenteredItemsContainer>

      <CenteredItemsContainer>
        <Avatar size={23} src={match?.home.logoUrl} />
        <FixtureText>{`${match?.home.name} - ${match?.away.name}`}</FixtureText>
        <Avatar size={23} src={match?.away.logoUrl} />
      </CenteredItemsContainer>

      <PredictionLabel token={prediction?.token} value={prediction?.value} odd={prediction?.odd} />

      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item name="amount">
          <FullWidthInputNumber
            min={0}
            max={1000}
            step={0.01}
            stringMode
            addonAfter={<CopyrightOutlined />}
            placeholder="Enter amount"
          />
        </Form.Item>

        <Form.Item name="description">
          <Input.TextArea rows={8} showCount={true} maxLength={300} placeholder="Add some thoughts about your bet" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
          <StyledButton htmlType="submit">Place</StyledButton>
          <StyledButton onClick={onClose}>Cancel</StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const StyledButton = styled(Button)`
  margin: 5px;
`;

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
  margin-top: 15px;
`;

const CenteredItemsContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const Text = styled.span`
  font-weight: 700;
`;

const FixtureText = styled(Text)`
  margin-left: 3px;
  margin-right: 3px;
`;

const Flag = styled(Image)`
  padding-right: 0.25em;
`;
