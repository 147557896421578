import { styles } from '../styles/styles';

export const fontSizes = {
  'extra-small': styles.fontSizeExtraSmall,
  small: styles.fontSizeSmall,
  normal: styles.fontSizeNormal,
  'section-title': styles.fontSizeSectionTitle,
  extra: styles.fontSizeExtraLarge,
  title: styles.fontSizeTitle,
};

export type FontSizes = 'extra-small' | 'small' | 'normal' | 'section-title' | 'extra' | 'title';
