import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import dayjs from 'dayjs';
import { Area, AreaChart, XAxis, CartesianGrid, YAxis, Tooltip } from 'recharts';
import { styles } from '../../../styles/styles';
import { styled } from 'styled-components';

export const ProfitChart: FC = () => {
  const { userStats } = useProfileService();

  let stat: { date: string; roi: number }[] = userStats.monthBetStats
    .map(x => ({ date: dayjs(x.date), roi: x.stats.roi }))
    .sort((a, b) => dayjs(a.date).diff(b.date))
    .map(x => ({ date: dayjs(x.date).format('MMM YY'), roi: x.roi }));

  let balance = 1000;
  let statWithBalance: { date: string; roi: number; balance: number }[] = stat.map(i => {
    balance = balance + i.roi;
    return {
      ...i,
      balance: balance,
    };
  });

  return (
    <Flex vertical>
      <DisplayText value="Profit Chart" weight="semi-bold" size="section-title" />
      <Space size={20} />
      <Container width={400} height={210} bordered innerSpace={10}>
        <AreaChart width={380} height={190} data={statWithBalance}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip active={undefined} payload={undefined} />} />
          <Area type="monotone" dataKey="balance" stroke={styles.primary} fill={styles.primary} fillOpacity={0.5} />
        </AreaChart>
      </Container>
    </Flex>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { date, balance } = payload[0].payload;
    return (
      <TooltipContainer>
        <Space size={10} />
        <Flex vertical align="center" justify="center" gap={10}>
          <DisplayText value={date} />
          <DisplayDecimal value={+balance} color={styles.primary} />
        </Flex>
      </TooltipContainer>
    );
  }
};

const TooltipContainer = styled.div`
  background-color: ${styles.white};
  height: 70px;
  width: 70px;
  border: 1px solid ${styles.grey};
`;
