import { Flex } from 'antd';
import React, { FC } from 'react';
import { DisplayText } from '../../../components';
import { LeaguePerformance } from './LeaguePerfomance';
import { OddRangePerformance } from './OddRangePerfomance';

export const ProfileStatistics: FC = () => (
  <Flex vertical gap={30}>
    <Flex vertical gap={10}>
      <DisplayText value="Perfomance By League" size="section-title" weight="semi-bold" />
      <LeaguePerformance />
    </Flex>

    <Flex vertical gap={10}>
      <DisplayText value="Perfomance By Odds" size="section-title" weight="semi-bold" />
      <OddRangePerformance />
    </Flex>
  </Flex>
);
