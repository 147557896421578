import React, { FC } from 'react';
import { Button, Col, Flex, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MatchDetails } from '../../../models';
import { BetGroupContainer } from './BetGroupContainer';
import { BetButton } from './BetButton';
import { BetAvatarButton } from './BetAvatarButton';
import draw from '../../../assets/icons/draw.svg';
import { Container, DisplayText, Space } from '../../../components';
import { styled } from 'styled-components';
import { styles } from '../../../styles/styles';

interface BetsProps {
  match: MatchDetails;
}

const Bets: FC<BetsProps> = ({ match }) => {
  return (
    <>
      <DisplayText value="Place a Bet" size="section-title" weight="semi-bold" />
      <Space size={20} />
      <Container innerSpace={15} bordered>
        <Flex vertical gap={20}>
          <Flex justify="space-between">
            <BetAvatarButton title={match?.match.home.name} src={match?.match.home.logoUrl} token="Home" />
            <BetAvatarButton title="Draw" src={draw} token="Draw" />
            <BetAvatarButton title={match?.match.away.name} src={match?.match.away.logoUrl} token="Away" />
          </Flex>

          <BetGroupContainer header="1X2 (Ordinary Time)" collapsed>
            <Flex vertical gap={6}>
              <BetButton token="HomeDraw" wide />
              <BetButton token="DrawAway" wide />
              <BetButton token="HomeAway" wide />
            </Flex>
          </BetGroupContainer>

          <BetGroupContainer header="Goal Scored" collapsed>
            <Flex vertical gap={6}>
              <BetButton token="HomeScored" wide />
              <BetButton token="AwayScored" wide />
              <BetButton token="BothScored" wide />
            </Flex>
          </BetGroupContainer>

          <BetGroupContainer header="Total Over/Under" collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton token="TotalOver15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="TotalUnder15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="TotalOver25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="TotalUnder25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="TotalOver35"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="TotalUnder35"></BetButton>
              </Col>
            </Row>
          </BetGroupContainer>

          <BetGroupContainer header={`${match?.match.home.name} Over/Under`} collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton token="HomeOver15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="HomeUnder15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="HomeOver25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="HomeUnder25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="HomeOver35"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="HomeUnder35"></BetButton>
              </Col>
            </Row>
          </BetGroupContainer>

          <BetGroupContainer header={`${match?.match.away.name} Over/Under`} collapsed>
            <Row gutter={[6, 6]}>
              <Col span={12}>
                <BetButton token="AwayOver15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="AwayUnder15"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="AwayOver25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="AwayUnder25"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="AwayOver35"></BetButton>
              </Col>
              <Col span={12}>
                <BetButton token="AwayUnder35"></BetButton>
              </Col>
            </Row>
          </BetGroupContainer>
        </Flex>

        <StyledButton>
          <Flex gap={15} justify="center" align="center">
            <DisplayText value="Show More" />
            <FontAwesomeIcon icon={faChevronDown} style={{ color: '#000000' }} />
          </Flex>
        </StyledButton>
      </Container>
    </>
  );
};

export default Bets;

const StyledButton = styled(Button)`
  width: 100%;
  height: 45px;
  background-color: ${styles.greyLight};
  border-radius: 11px;
  border: none;
  margin-top: 15px;
  box-shadow: none;
`;
