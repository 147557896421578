import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { DisplayText } from './DisplayText';

interface Props {
  isHomeTeam?: boolean;
  value?: number;
}

export const DisplayGoals: FC<PropsWithChildren<Props>> = ({ isHomeTeam = false, value }) => (
  <StyledGoalsFrame $isHomeTeam={isHomeTeam}>
    <DisplayText value={value ?? '-'} textAlign='center' />
  </StyledGoalsFrame>
);

export const StyledGoalsFrame = styled.div<{ $isHomeTeam: boolean }>`
  width: 17px;
  height: 22px;
  border: 2px solid #c6c6c6;
  margin-top: 5px;
  ${props =>
    props.$isHomeTeam
      ? `
    border-radius: 4px 4px 0px 0px;
  `
      : `
    border-radius: 0px 0px 4px 4px;
  `}
  margin-left: auto;
  margin-right: auto;
`;
