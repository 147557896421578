import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';
import App from './App';
import './index.scss';
import {
  MatchesListScreen,
  MatchDetailsScreen,
  BetsScreen,
  ProfileScreen,
  ProfileStatistics,
  ProfileSummary,
  MatchStatistics,
  MatchPreview,
} from './screens';
import { ProfileBets } from './screens/Profile/Bets/ProfileBets';
import { PlayerStats } from './screens/MatchDetails/PlayerStats/PlayerStats';
const container = document.getElementById('root');
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/matches" />,
      },
      {
        path: '/matches',
        element: <MatchesListScreen />,
      },
      {
        path: '/matches/:id',
        element: <MatchDetailsScreen />,
        children: [
          {
            path: '',
            element: <MatchPreview />,
          },
          {
            path: 'statistics',
            element: <MatchStatistics />,
          },
          {
            path: 'player-stats',
            element: <PlayerStats />,
          },
        ],
      },
      {
        path: '/bets',
        element: <BetsScreen />,
      },
      {
        path: '/users/:id',
        element: <ProfileScreen />,
        children: [
          {
            path: '',
            element: <ProfileSummary />,
          },
          {
            path: 'profile-statistics',
            element: <ProfileStatistics />,
          },
          {
            path: 'bets',
            element: <ProfileBets />,
          },
        ],
      },
    ],
  },
]);

ReactGA.initialize('G-FEM3NK48P3');
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
