import { Flex } from 'antd';
import React, { FC } from 'react';
import { Container, DisplayText, Space } from '../../../components';
import MatchInfo from './MatchInfo';
import WhoWillWin from './WhoWillWin';
import TournamentPreview from './TournamentPreview';
import { useMatchDetailsService } from '../MatchDetailsService';
import HeadToHeadMatches from './HeadToHeadMatches';
import LastMatchesList from './LastMatchesList';
import OtherMatches from './OtherMatches';
import LeagueBasicStats from './LeagueBasicStats';
import dayjs from 'dayjs';

export const MatchPreview: FC = () => {
  const { match } = useMatchDetailsService();

  return (
    <Flex justify="center" gap={30}>
      <Container width={285}>
        <Flex vertical>
          <Flex vertical>
            <DisplayText value="Match Info" size="section-title" weight="semi-bold" />
            <Space size={20} />
            <MatchInfo />
          </Flex>
        </Flex>

        <Space size={20} />
        <WhoWillWin />

        <Space size={20} />
        <TournamentPreview />

        <Space size={20} />
        <LeagueBasicStats />
      </Container>

      <Container width={615}>
        <Flex vertical>
          <DisplayText value="Head-to-Head Matches" size="section-title" weight="semi-bold" />
          <Space size={20} />
          <HeadToHeadMatches
            matches={match?.headToHeadMatches
              .sort((a, b) => (dayjs(b.scheduledAt).isAfter(a.scheduledAt) ? 1 : -1))
              .slice(0, 5)}
            homeTeam={match?.match.home}
            awayTeam={match?.match.away}
          />
        </Flex>

        <Space size={20} />

        <Flex vertical>
          <DisplayText value="Last Matches" size="section-title" weight="semi-bold" />
          <Space size={20} />
          <Flex gap={15}>
            <Container width={300}>
              <LastMatchesList matches={match?.homeTeamMatches.slice(0, 5)} team={match?.match.home} />
            </Container>
            <Container width={300}>
              <LastMatchesList matches={match?.awayTeamMatches.slice(0, 5)} team={match?.match.away} />
            </Container>
          </Flex>
        </Flex>

        <Space size={20} />

        <Flex vertical>
          <DisplayText value={`Other matches in ${match.match.league}`} size="section-title" weight="semi-bold" />
          <Space size={20} />
          <OtherMatches />
        </Flex>
      </Container>
    </Flex>
  );
};
