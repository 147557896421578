import React from 'react';
import { FC } from 'react';
import { styled } from 'styled-components';
import { styles } from '../styles/styles';
import { fontWeights, fontSizes, FontSizes, FontWeights } from '../utils';

interface Props {
  value?: number;
  color?: string;
  size?: FontSizes;
  weight?: FontWeights;
  textAlign?: 'start' | 'center' | 'end';
  showCurrency?: boolean;
}

export const DisplayDecimal: FC<Props> = ({
  value,
  size = 'small',
  color = styles.black,
  weight = 'normal',
  textAlign = 'start',
  showCurrency = false
}) => {
  const text = value.toFixed(2);
  if (value !== null && value !== undefined) {
    return (
      <StyledText $color={color} $size={fontSizes[size]} $weight={fontWeights[weight]} $textAlign={textAlign}>
        {text}{showCurrency ? '$' : ''}
      </StyledText>
    );
  }
};

const StyledText = styled.div<{ $color: string; $size: string; $weight: number; $textAlign: string }>`
  color: ${props => props.$color};
  font-size: ${props => props.$size};
  font-weight: ${props => props.$weight};
  text-align: ${props => props.$textAlign};
`;
