import { Flex } from 'antd';
import React, { FC } from 'react';
import { BetCard, Container, Loading, NoItems, Space } from '../../../components';
import { useProfileService } from '../ProfileService';

export const ProfileBets: FC = () => {
  const { userBets, loading } = useProfileService();
  return (
    <Loading loading={loading}>
      <Container width={825}>
        <Flex vertical align="center" gap={10}>
          {userBets?.map(b => <BetCard key={b.id} bet={b} />)}
        </Flex>
        {userBets?.length === 0 && (
          <>
            <Space size={40} />
            <NoItems value="No Bets" width={825} />
          </>
        )}
      </Container>
    </Loading>
  );
};
