import React, { FC } from 'react';
import { Avatar, Button, Flex } from 'antd';
import { useMatchDetailsService } from '../MatchDetailsService';
import { Container, DisplayText } from '../../../components';
import { styled } from 'styled-components';
import { styles } from '../../../styles/styles';

const WhoWillWin: FC = () => {
  const { vote, myVote, match } = useMatchDetailsService();
  const [isPressed, setIsPressed] = React.useState(!!myVote);
  const { home, draw, away } = match?.matchVote;
  const totalVotes = home + draw + away;

  const onVote = (type: string) => {
    setIsPressed(!isPressed);
    vote(type);
  };

  return (
    <Container bordered innerSpace={15}>
      <Flex vertical align="center" gap={10}>
        <DisplayText value={`${match?.match.home.name} - ${match?.match.away.name}`} textAlign='center' />
        <DisplayText size="section-title" weight="semi-bold" value="WHO WILL WIN?" />
        <Flex gap={5}>
          {!isPressed && (
            <>
              <StyledButton onClick={() => onVote('Home')} $bgColor={styles.primaryLight}>
                <Avatar size={35} src={match.match.home.logoUrl} />
              </StyledButton>
              <StyledButton onClick={() => onVote('Draw')} $bgColor={styles.yellowLight}>
                <DisplayText size="section-title" weight="semi-bold" value="X" textAlign='center' />
              </StyledButton>
              <StyledButton onClick={() => onVote('Away')} $bgColor={styles.redLight}>
                <Avatar size={35} src={match.match.away.logoUrl} />
              </StyledButton>
            </>
          )}
          {isPressed && (
            <>
              <StyledButton $bgColor={styles.primaryLight}>
                <DisplayText weight="semi-bold" size="section-title" value={home} textAlign='center' />
              </StyledButton>
              <StyledButton $bgColor={styles.yellowLight}>
                <DisplayText weight="semi-bold" size="section-title" value={draw} textAlign='center' />
              </StyledButton>
              <StyledButton $bgColor={styles.redLight}>
                <DisplayText weight="semi-bold" size="section-title" value={away} textAlign='center' />
              </StyledButton>
            </>
          )}
        </Flex>

        <DisplayText value={`${totalVotes} people voted`} />
      </Flex>
    </Container>
  );
};

const StyledButton = styled(Button)<{
  $bgColor: string;
}>`
  width: 80px;
  height: 50px;
  background-color: ${props => props.$bgColor};
`;

export default WhoWillWin;
