import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { styles } from '../styles/styles';

interface Props {
  width?: number;
  height?: number;
  bordered?: boolean;
  innerSpace?: number;
  borderColor?: string;
}

export const Container: FC<PropsWithChildren<Props>> = ({
  children,
  width,
  height,
  bordered = false,
  innerSpace = 0,
  borderColor = styles.greyDark,
}) => (
  <StyledContainer
    $width={width}
    $height={height}
    $bordered={bordered}
    $innerSpace={innerSpace}
    $borderColor={borderColor}>
    {children}
  </StyledContainer>
);

const StyledContainer = styled.div<{
  $width?: number;
  $height?: number;
  $bordered: boolean;
  $innerSpace: number;
  $borderColor: string;
}>`
  margin-left: auto;
  margin-right: auto;

  ${props =>
    props.$height &&
    `
    height: ${props.$height}px;
  `}

  ${props => (props.$width ? `width: ${props.$width}px;` : `width: 100%;`)}

  padding: ${props => props.$innerSpace}px;

  ${props =>
    props.$bordered &&
    `
    border-radius: 13px;
    box-shadow: 0px 0px 5px 0px ${props.$borderColor};
  `}
`;
