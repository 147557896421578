import React from 'react';
import { Prediction } from '../models';
import { useApi } from './api';

export function usePredictionsApi() {
  const { api } = useApi();

  const getRecommended = React.useCallback(async () => {
    return await api<null, Prediction[]>('GET', 'predictions');
  }, [api]);

  return {
    getRecommended,
  };
}
