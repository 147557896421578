import React, { FC } from 'react';
import style from './BetsBarChart.module.scss';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';

export const BetsBarChart: FC = () => {
  const { userStats } = useProfileService();
  const {won, pending, lost} = userStats.monthBetStats.length > 0 
                                ? userStats.monthBetStats[0].stats 
                                : {
                                  won: 0,
                                  pending: 0,
                                  lost: 0
                                };
  const betsSum = won + pending + lost;

  return (
    <div className={style.Container}>
      <Flex>
        <div className={`${style.Line} ${style.Green}`} style={{ width: `${(won * 290) / betsSum}px` }} />
        <p className={`${style.Text} ${style.TextGreen}`}>{`${won} +`}</p>
      </Flex>
      <Flex>
        <div className={`${style.Line} ${style.Yellow}`} style={{ width: `${(pending * 290) / betsSum}px` }} />
        <p className={`${style.Text} ${style.TextYellow}`}>{`${pending} =`}</p>
      </Flex>
      <Flex>
        <div className={`${style.Line} ${style.Red}`} style={{ width: `${(lost * 290) / betsSum}px` }} />
        <p className={`${style.Text} ${style.TextRed}`}>{`${lost} -`}</p>
      </Flex>
    </div>
  );
};
