import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { useMatchDetailsService } from '../MatchDetailsService';
import { Container, DisplayText } from '../../../components';
import { styled } from 'styled-components';

const MatchInfo: FC = () => {
  const {
    match: {
      match: { stadium, city },
    },
  } = useMatchDetailsService();

  return (
    <Container bordered innerSpace={15}>
      <Row>
        <Col span={6}>
          <Row>
            <DisplayText value="Stadium" />
          </Row>
          <Row>
            <DisplayText value="Location" />
          </Row>
        </Col>

        <Col offset={1} span={17}>
          <RightAlignedRow>
            <DisplayText value={stadium} overflow />
          </RightAlignedRow>
          <RightAlignedRow>
            <DisplayText value={city} overflow />
          </RightAlignedRow>
        </Col>
      </Row>
    </Container>
  );
};

const RightAlignedRow = styled(Row)`
  justify-content: flex-end;
  white-space: nowrap;
`;

export default MatchInfo;
