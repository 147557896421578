import React, { FC } from 'react';
import { Row } from 'antd';
import { Prediction } from '../../../models';
import './GridPrediction.scss';

interface Props {
  prediction: Prediction;
  onClick: () => void;
}

const getColor = (value: number) => {
  if (value < 75) return 'red';
  if (value < 85) return 'gold';
  return 'green';
};

const GridPrediction: FC<Props> = ({ prediction: { value, token, odd }, onClick }) => {
  return (
    <div className="grid-prediction-container" onClick={onClick}>
      <Row className="grid-prediction-container-row-token">
        <p>
          {token}
          {value && <span className="dash">-</span>}
          {value && <span className={`grid-prediction-container-row-token__${getColor(value)}`}>{value} %</span>}
        </p>
      </Row>
      <Row>
        {odd && (
          <div className="grid-prediction-container-row-value">
            <p>{odd}</p>
          </div>
        )}
      </Row>
    </div>
  );
};

export default GridPrediction;
