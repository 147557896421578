import React, { FC } from 'react';
import MatchesGrid from './components/MatchesGrid';
import MatchesGridFilters from './components/MatchesGridFilters';
import { WelcomeWindow, RecommendedBets } from './components';
import { useMatchesListService } from './MatchesListService';
import { Container, Loading, Space } from '../../components';

export const MatchesListScreen: FC = () => {
  const { config, setConfig, loading } = useMatchesListService();
  const ref = React.useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Loading loading={loading}>
      <WelcomeWindow handleOnClick={handleClick} />

      <Container width={1400}>
        <Space size={40} />

        <RecommendedBets />

        <Space size={40} />

        <Container width={1000}>
          <MatchesGridFilters filter={config} onFilter={c => setConfig(c)} anchor={ref} />

          <Space size={40} />

          <MatchesGrid />

          <Space size={40} />
        </Container>
      </Container>
    </Loading>
  );
};
