import React, { FC } from 'react';
import { Flex } from 'antd';
import { Container, DisplayText, Space } from '../../../components';
import { WinRate } from './WinRate';
import { ProfitChart } from './ProfitChart';
import { BetsStatistics } from './BetsStatistics';
import { Performance } from './Performance';
import { ProfitStatistics } from './ProfitStatistics';

export const ProfileSummary: FC = () => {
  const aboutMeText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a lobortis elit, nec commodo lacus. Nuncinterdum mollis nulla, eget gravida ipsum placerat iaculis. Suspendisse potenti. Mauris nec semper mi.';

  return (
    <Flex vertical gap={30}>
      <Flex vertical>
        <DisplayText value="About Me" size="section-title" weight="semi-bold" />

        <Space size={20} />

        <Container bordered innerSpace={20}>
          <DisplayText weight="normal" size="normal" value={aboutMeText} />
        </Container>
      </Flex>

      <Flex justify="space-between">
        <WinRate />
        <ProfitChart />
      </Flex>

      <DisplayText value="Statistics For The Current Month" size="section-title" weight="semi-bold" />

      <Flex justify="space-between">
        <ProfitStatistics />
        <BetsStatistics />
      </Flex>

      <Flex vertical gap={20}>
        <DisplayText value="Perfomance By Month" size="section-title" weight="semi-bold" />
        <Performance />
      </Flex>
    </Flex>
  );
};
