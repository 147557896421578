import React, { FC } from 'react';
import { Streak } from '../../../models';
import { Container, DisplayText, Table } from '../../../components';
import { Avatar } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface Props {
  streaks: Streak[];
  logo: string;
}

const columns: ColumnsType<Streak> = [
  {
    key: 'logo',
    dataIndex: 'logo',
    align: 'center',
    render: (logo: string) => <Avatar size={30} src={logo} />,
  },
  {
    key: 'token',
    dataIndex: 'token',
    align: 'center',
    render: (token: string) => <DisplayText value={token} />,
  },
  {
    key: 'value',
    dataIndex: 'value',
    align: 'center',
    render: (value: number, record: Streak) => (
      <DisplayText value={`${record.total ? value + '/' + record.total : value}`} />
    ),
  },
];

export const StreaksList: FC<Props> = ({ streaks, logo }) => {
  const data = streaks.sort((a: Streak, b: Streak) => b.value - a.value).map(streak => ({ ...streak, logo }));
  return (
    <Container bordered innerSpace={15}>
      <Table showHeader={false} pagination={false} columns={columns} data={data} rowKey={'logo'} cellPadding={6} />
    </Container>
  );
};
