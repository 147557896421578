import React, { FC, useMemo } from 'react';
import { Button, Flex, Image, Space, Typography } from 'antd';
import downArrow from '../../../assets/icons/downArrow.svg';
import style from './WelcomeWindow.module.scss';
import { useSignUpModalService } from '../../../components';
import { useCognito } from '../../../auth';

interface WelcomeWindowProps {
  handleOnClick: () => void;
}

const WelcomeWindow: FC<WelcomeWindowProps> = ({ handleOnClick }) => {
  const { show } = useSignUpModalService();
  const { currentUser } = useCognito();
  const username = useMemo(() => currentUser?.getUsername(), [currentUser]);
  const { Text } = Typography;

  return (
    <Flex vertical={true} className={style.Container}>
      <Flex justify="space-around">
        <Space className={style.Info}>
          <Text className={style.Header}>BetTrayer</Text>
          <Text className={style.SubHeader}>
            Empowering Success, Unveiling Insights – Where Experts Share <br /> Winning Strategies for Tomorrow's
            Victories.
          </Text>
        </Space>
        {!username && (
          <Button type="primary" size="large" className={style.Button} onClick={show}>
            Join Now
          </Button>
        )}
      </Flex>
      <Space className={style.AnchorLink} onClick={handleOnClick}>
        <Text className={style.AnchorText}>Show Matches</Text>
        <Image className={style.ArrowIcon} src={downArrow} preview={false} />
      </Space>
    </Flex>
  );
};

export default WelcomeWindow;
