import React, { FC } from 'react';
import { Avatar, Button, Flex } from 'antd';
import { useMatchDetailsService } from '../MatchDetailsService';
import { DisplayText, useCreateBetModalService, Container } from '../../../components';
import { styled } from 'styled-components';
import { styles } from '../../../styles/styles';

interface Props {
  token: string;
  src: string;
  title: string;
}

export const BetAvatarButton: FC<Props> = ({ token, src, title }) => {
  const {
    match: {
      match: { id, predictions },
    },
  } = useMatchDetailsService();
  const { show } = useCreateBetModalService();

  const prediction = predictions.find(x => x.token === token);
  const valueText = prediction?.value ? `${prediction.value}%` : '';
  const oddText = prediction?.odd ?? '';
  const footerText = valueText && oddText ? `${valueText} - ${oddText}` : `${valueText}${oddText}`;

  return (
    <BigButton onClick={() => show(token, id)}>
      <Container height={70}>
        <Flex vertical align="center" justify="center" gap={5}>
          <Avatar size={33} src={src} />
          <DisplayText value={title} size="extra-small" textAlign="center" />
        </Flex>
      </Container>

      {footerText && (
        <ValueContainer $value={prediction?.value} align="center" justify="center">
          <DisplayText value={footerText} size="extra-small" />
        </ValueContainer>
      )}
    </BigButton>
  );
};

const BigButton = styled(Button)`
  height: 150px;
  width: 100px;
  border: 1px solid ${styles.greyDark};
`;

const ValueContainer = styled(Flex)<{ $value: number }>`
  height: 25px;
  background-color: ${props => {
    if (props.$value === null) return styles.greyLight;
    if (props.$value < 75) return styles.redLight;
    if (props.$value < 85) return styles.yellowLight;
    return styles.primaryLight;
  }};
  border-radius: 5px;
  padding: 2px 5px;
  margin-top: 7px;
`;
