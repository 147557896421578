import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { FC, PropsWithChildren, useMemo } from 'react';
import toast from 'react-hot-toast';

interface Options {
  onError?(error: Error | unknown): void;
}

export const ApiProvider: FC<PropsWithChildren> = props => {
  const { children } = props;

  const createQueryClient = (options: Options): QueryClient => {
    const { onError = () => undefined } = options;

    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          refetchOnReconnect: false,
          retry: false,
          staleTime: 5 * 60 * 1000,
        },
      },
      queryCache: new QueryCache({
        onError: error => onError(error),
      }),
      mutationCache: new MutationCache({
        onError: error => onError(error),
      }),
    });
  };

  const queryClient = useMemo(
    () =>
      createQueryClient({
        onError: (error: any) => toast.error(String(error?.message ?? error?.statusText ?? error?.detail ?? error)),
      }),
    [],
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
