import React, { FC } from 'react';
import { Button, Flex } from 'antd';
import { PredictionTokens } from '../../../models';
import { useMatchDetailsService } from '../MatchDetailsService';
import { DisplayText, useCreateBetModalService } from '../../../components';
import { styled } from 'styled-components';
import { styles } from '../../../styles/styles';

export interface Props {
  token: string;
  wide?: boolean;
}

export const BetButton: FC<Props> = ({ token, wide = false }) => {
  const { match } = useMatchDetailsService();
  const { show } = useCreateBetModalService();
  const header = PredictionTokens[token];

  const prediction = match.match.predictions.find(x => x.token === token);

  const leftValue = prediction?.value ? `${prediction?.value}%` : '';
  const rightValue = prediction?.odd ?? '';
  const value = leftValue && rightValue ? `${leftValue} / ${rightValue}` : `${leftValue}${rightValue}`;

  if (!!prediction?.value === false && !!prediction?.odd === false) {
    return;
  }

  if (wide) {
    return (
      <Bet onClick={() => show(token, match.match.id)}>
        <Flex justify='space-between'>
          <DisplayText value={header} size='normal' />
          <ValueContainer $value={prediction.value}>
            <DisplayText value={value} textAlign='center' size='normal' />
          </ValueContainer>
        </Flex>
      </Bet>
    );
  }

  return (
    <Bet onClick={() => show(token, match.match.id)}>
      <Flex align='center' justify='center' vertical>
        <DisplayText value={header} />
        <ValueContainer $value={prediction.value}>
          <DisplayText value={value} textAlign='center' />
        </ValueContainer>
      </Flex>
    </Bet>
  );
}

const Bet = styled(Button)`
  width: 100%;
  min-height: 50px;
  height: 100%;
  border: 1px solid ${styles.greyDark};
  border-radius: 11px;
  padding: 3px 10px;
`;

const ValueContainer = styled.div<{ $value?: number }>`
  padding: 2px 1px 2px 1px;
  background-color: ${props => {
    if (props.$value === null) return styles.greyLight;
    if (props.$value < 75) return styles.redLight;
    if (props.$value < 85) return styles.yellowLight;
    return styles.primaryLight;
  }};
  border-radius: 5px;
`;
