import React, { FC } from 'react';
import { styles } from '../../../../styles/styles';
import { styled } from 'styled-components';
import { DisplayDecimal, DisplayPercentage, DisplayText, Table } from '../../../../components';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Flex } from 'antd';
import { DisplayInjury } from './index';
import { PassingPlayer, Injury } from '../../../../models';

interface Props {
  players: PassingPlayer[];
  team: string;
  avatar: string;
}

const columns: ColumnsType<PassingPlayer> = [
  {
    key: 'player',
    title: 'Player',
    align: 'center',
    render: (row: PassingPlayer) => (
      <Flex gap={5} align="center">
        <Avatar size={30} src={row.avatar} />
        <DisplayText value={row.name} />
      </Flex>
    ),
  },
  {
    key: 'rating',
    title: 'Rating',
    dataIndex: 'rating',
    align: 'center',
    width: 80,
    render: (rating: string) => <DisplayText value={rating} textAlign="center" />,
  },
  {
    key: 'minutes',
    title: 'Minutes',
    dataIndex: 'minutes',
    align: 'center',
    width: 80,
    render: (minutes: number) => <DisplayPercentage value={minutes} textAlign="center" />,
  },
  {
    key: 'averagePoints',
    title: 'Points',
    align: 'center',
    width: 100,
    render: (row: PassingPlayer) => {
      const value = `${row.averagePointsWithPlayer}/${row.averageTeamPoints}/${row.averagePointsWithoutPlayer}`;
      return <DisplayText value={value} textAlign="center" />;
    },
  },
  {
    key: 'assists',
    title: 'Assisits',
    dataIndex: 'assists',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'totalPasses',
    title: 'Total Passes',
    dataIndex: 'totalPasses',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'keyPasses',
    title: 'Key Passes',
    dataIndex: 'keyPasses',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'passesAccuracy',
    title: 'Passes Accuracy',
    dataIndex: 'passesAccuracy',
    align: 'center',
    width: 90,
    render: (value: number) => <DisplayDecimal value={value} textAlign="center" />,
  },
  {
    key: 'injury',
    dataIndex: 'injury',
    title: 'Availability',
    width: 90,
    render: (injury?: Injury) => <DisplayInjury injury={injury} />,
  },
];

export const PassingPlayersTable: FC<Props> = ({ players, team, avatar }) => {
  return (
    <Flex vertical gap={10}>
      <TeamHeader>
        <Flex gap={10} justify="center" align="center">
          <Avatar size={30} src={avatar} />
          <DisplayText value={team} />
        </Flex>
      </TeamHeader>
      <Table showHeader pagination={false} columns={columns} data={players} rowKey={'name'} cellPadding={8} />
    </Flex>
  );
};

const TeamHeader = styled.div`
  width: 100%;
  height: 45px;
  background-color: ${styles.primaryLight};
  border-radius: 10px;
  padding-top: 7px;
`;
