import React, { FC, MutableRefObject } from 'react';
import { DatePicker, Select, Avatar, Button, Flex } from 'antd';
import { CheckCircleOutlined, CalendarOutlined, PieChartOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MatchesGridConfig } from '../../../models';
import { useMatchesListService } from '../MatchesListService';
import { DisplayText, Space } from '../../../components';
import { styles } from '../../../styles/styles';
import { styled } from 'styled-components';

export interface MatchesGridFiltersProps {
  filter: MatchesGridConfig;
  onFilter: (filter: MatchesGridConfig) => void;
  anchor: MutableRefObject<any>;
}

const MatchesGridFilters: FC<MatchesGridFiltersProps> = ({ anchor }) => {
  const { leagues, config, setConfig } = useMatchesListService();
  const onSelectLeagues = (value: string[]) => setConfig({ ...config, leagueIds: value });
  const onChangeDate = (dateString: string | string[]) => setConfig({ ...config, date: dateString as string });

  return (
    <Flex ref={anchor} vertical={true} justify="center">
      <DisplayText value="Matches" size="title" />

      <Space size={10} />

      <Flex justify="space-between">
        <StyledButton
          type={config.status === 'all' ? 'primary' : 'default'}
          icon={<PieChartOutlined />}
          onClick={() => setConfig({ ...config, status: 'all' })}>
          All
        </StyledButton>

        <StyledButton
          type={config.status === 'upcoming' ? 'primary' : 'default'}
          icon={<CalendarOutlined />}
          onClick={() => setConfig({ ...config, status: 'upcoming' })}>
          Upcoming
        </StyledButton>

        <StyledButton
          type={config.status === 'finished' ? 'primary' : 'default'}
          icon={<CheckCircleOutlined />}
          onClick={() => setConfig({ ...config, status: 'finished' })}>
          Finished
        </StyledButton>

        <StyledSelect
          mode={'multiple'}
          placeholder="Please select league"
          value={config.leagueIds}
          onChange={(value, _) => onSelectLeagues(value as string[])}
          optionLabelProp="label">
          {leagues.map(l => (
            <Select.Option key={l.id} value={l.id} label={l.name}>
              <Flex align="center">
                <Avatar size={30} src={l.countryFlag} />
                <span>{l.name}</span>
              </Flex>
            </Select.Option>
          ))}
        </StyledSelect>

        <StyledDatepicker
          allowClear={false}
          onChange={(_, dateString) => onChangeDate(dateString)}
          defaultValue={dayjs(config.date)}
          format={'DD MMM YYYY'}
        />
      </Flex>
    </Flex>
  );
};

const StyledButton = styled(Button)`
  height: 35px;
  font-size: ${styles.fontSizeSmall};
  font-weight: ${styles.fontWeightNormal};
  text-transform: uppercase;
`;

const StyledSelect = styled(Select)`
  height: 35px;
  width: 290px;
  font-size: ${styles.fontSizeSmall};
  font-weight: ${styles.fontWeightNormal};
  text-transform: uppercase;

  input {
    font-size: ${styles.fontSizeSmall};
    font-weight: ${styles.fontWeightNormal};
    text-transform: uppercase;
  }
`;

const StyledDatepicker = styled(DatePicker)`
  height: 35px;
  width: 290px;
  font-size: ${styles.fontSizeSmall};
  font-weight: ${styles.fontWeightNormal};
  text-transform: uppercase;

  input {
    font-size: ${styles.fontSizeSmall};
    font-weight: ${styles.fontWeightNormal};
    text-transform: uppercase;
  }
`;

export default MatchesGridFilters;
