import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { styles } from '../styles/styles';

interface Props {
  onClick?: MouseEventHandler<HTMLElement>;
}

export const Clickable: FC<Props & PropsWithChildren> = ({ children, onClick }) => (
  <ClickableContainer onClick={onClick}>{children}</ClickableContainer>
);

export const ClickableContainer = styled.div`
  cursor: pointer !important;

  &:hover span {
    color: ${styles.primary};
  }
`;
