import React, { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useProfileService } from '../ProfileService';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart: FC = () => {
  const { userStats } = useProfileService();
  const data = {
    datasets: [
      {
        data: [userStats.annual.won, userStats.annual.pending, userStats.annual.lost],
        backgroundColor: ['#00be8c', '#ffd97d', '#ea274b'],
        borderWidth: 2,
        radius: '90%',
      },
    ],
  };

  return <Doughnut data={data} />;
};
