import React from 'react';
import { DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import { FullTournament } from './FullTournament';
import { useMatchDetailsService } from '../MatchDetailsService';
import { RivalLevelMatches } from './RivalLevelMatches';
import { StreaksList } from './StreaksList';

export const MatchStatistics = () => {
  const { match } = useMatchDetailsService();
  return (
    <Flex vertical>
      <Flex vertical>
        <DisplayText value="Matches against rival level" size="section-title" weight="semi-bold" />
        <Space size={30} />
        <Flex gap={20}>
          <RivalLevelMatches matches={match.homeTeamRivalLevelMatches} team={match.match.home} />
          <RivalLevelMatches matches={match.awayTeamRivalLevelMatches} team={match.match.away} />
        </Flex>
      </Flex>

      <Space size={30} />

      <Flex vertical>
        <DisplayText value="Team Streaks" size="section-title" weight="semi-bold" />
        <Space size={30} />
        <Flex gap={20}>
          <StreaksList streaks={match.homeTeamStreaks} logo={match.match.home.logoUrl} />
          <StreaksList streaks={match.awayTeamStreaks} logo={match.match.away.logoUrl} />
        </Flex>
      </Flex>

      <Space size={30} />

      <Flex vertical>
        <DisplayText value="Tournament" size="section-title" weight="semi-bold" />
        <Space size={30} />
        <FullTournament />
      </Flex>
    </Flex>
  );
};
