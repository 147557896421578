import React, { FC, PropsWithChildren } from 'react';
import style from './Loading.module.scss';
import ClipLoader from 'react-spinners/ClipLoader';

interface Props {
  loading: boolean;
}

export const Loading: FC<PropsWithChildren<Props>> = ({ loading, children }) => {
  if (!loading) return <>{children}</>;

  return (
    <div className={style.Background}>
      <div className={style.Container}>
        <ClipLoader className={style.Spinner} color="#000000" />
        <p>Loading...</p>
      </div>
    </div>
  );
};
