export const styles = {
  background: '#ffffff',
  white: '#ffffff',
  whiteHover: '#ebebeb',
  primary: '#00be8c',
  primaryHover: '#00956d',
  primaryDark: '#00261c',
  secondaryDark: '#36524a',
  primaryLight: '#d3f7e8',
  primaryLightHover: '#f3fffa',
  primaryDisabled: '#00976f',
  borderColor: '#c6c6c6',
  black: '#000000',
  red: '#ea274b',
  redLight: '#ff8b8b',
  yellow: '#ffd97d',
  yellowLight: '#fffdba',
  grey: '#d9d9d9',
  greyLight: '#efefef',
  greyDark: '#bdbdbd',
  fontWeightSmall: 400,
  fontWeightNormal: 500,
  fontWeightMedium: 600,
  fontWeightSemiBold: 700,
  fontFamilyMain: 'Montserrat',
  fontFamilySecondary: 'Raleway',
  fontSizeExtraSmall: '12px',
  fontSizeSmall: '14px',
  fontSizeNormal: '16px',
  fontSizeSectionTitle: '20px',
  fontSizeExtraLarge: '24px',
  fontSizeTitle: '30px',
};
