import React, { FC } from 'react';
import { Flex } from 'antd';
import { Container, DisplayText, Space } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { styles } from '../styles/styles';
import { styled } from 'styled-components';

interface Props {
  width?: number;
  value: string | number;
}

export const NoItems: FC<Props> = ({ value, width = null }) => (
  <>
    <Space size={20} />
    <Container width={width} bordered innerSpace={20}>
      <Flex vertical align="center" gap="small">
        <StyledIcon icon={faListCheck} />
        <DisplayText value={value} size="extra" weight="medium" color={styles.grey} />
      </Flex>
    </Container>
  </>
);

const StyledIcon = styled(FontAwesomeIcon)`
  width: 50px;
  height: 50px;
  color: ${styles.grey};
`;
