import React, { FC } from 'react';
import { HeadToHeadMatch, Match, Team } from '../models';
import { DisplayText } from './index';
import styled from 'styled-components';
import { styles } from '../styles/styles';
import { Space } from 'antd';

interface Props {
  match: Match | HeadToHeadMatch;
  team: Team;
  size?: number;
  showLetter?: boolean;
}

export const ResultBadge: FC<Props> = ({ match, team, size = 20, showLetter = true }) => {
  let resultColor: string;
  let resultLetter: string;
  if (match.homeGoals! === match.awayGoals!) {
    resultColor = 'yellow';
    resultLetter = 'D';
  } else if (
    (match.homeGoals! > match.awayGoals! && match.home.id === team.id) ||
    (match.homeGoals! < match.awayGoals! && match.away.id === team.id)
  ) {
    resultColor = 'primary';
    resultLetter = 'W';
  } else {
    resultColor = 'red';
    resultLetter = 'L';
  }

  return (
    <Circle $color={resultColor} $size={size} align="center" direction="vertical">
      {showLetter && <DisplayText value={resultLetter} weight="medium" color={styles.white} />}
    </Circle>
  );
};

const Circle = styled(Space)<{
  $size: number;
  $color: string;
}>`
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
  background-color: ${props => styles[props.$color]};
  border-radius: 100%;
`;
