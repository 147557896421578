import React, { FC } from 'react';
import { Container, DisplayText, Loading, NavTab, Space } from '../../components';
import { Flex, Menu, MenuProps } from 'antd';
import { useProfileService } from './ProfileService';
import { Outlet } from 'react-router-dom';
import { TopForecasters, UserInfo } from './components';
import { styled } from 'styled-components';
import { styles } from '../../styles/styles';

const items: MenuProps['items'] = [
  {
    label: <NavTab to="">PROFILE PREVIEW</NavTab>,
    key: '',
  },
  {
    label: <NavTab to="bets">BETS</NavTab>,
    key: 'bets',
  },
  {
    label: <NavTab to="profile-statistics">PROFILE STATISTICS</NavTab>,
    key: 'profile-statistics',
  },
];

export const ProfileScreen: FC = () => {
  const { loading } = useProfileService();
  const { annualTop, monthTop } = useProfileService();
  const [selectedKey, setSelectedKey] = React.useState('');

  const handleClickTab: MenuProps['onClick'] = e => {
    setSelectedKey(e.key);
  };

  return (
    <Loading loading={loading}>
      <UserInfo />
      <StyledMenu selectedKeys={[selectedKey]} onClick={handleClickTab} mode="horizontal" items={items} />

      <Space size={30} />

      <Flex>
        <Container width={840}>
          <Outlet />
        </Container>

        <Container width={340}>
          <Flex vertical={true} gap={30}>
            <Flex vertical gap={10}>
              <DisplayText value="TOP Forecasters This Month" size="section-title" weight="semi-bold" />
              <TopForecasters users={monthTop} />
            </Flex>

            <Flex vertical gap={10}>
              <DisplayText value="TOP Forecasters All Time" size="section-title" weight="semi-bold" />
              <TopForecasters users={annualTop} />
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Space size={30} />
    </Loading>
  );
};

const StyledMenu = styled(Menu)`
  background-color: ${styles.secondaryDark};
  height: 56px;
  padding: 5px 0 0 150px;
  position: sticky;
  z-index: 100;
  top: 0px;;
`;
