import React, { FC } from 'react';
import { Container, DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import { styles } from '../../../styles/styles';
import { DoughnutChart } from './DoughnutChart';

export const WinRate: FC = () => {
  const { userStats } = useProfileService();
  const sumWLD = userStats.annual.won + userStats.annual.pending + userStats.annual.lost;
  const wonPercent = ((userStats.annual.won * 100) / sumWLD).toFixed(2);
  const lostPercent = ((userStats.annual.lost * 100) / sumWLD).toFixed(2);

  return (
    <Flex vertical>
      <DisplayText weight="semi-bold" value="Win Rate" size="section-title" />
      <Space size={20} />
      <Container width={400} height={210} bordered innerSpace={20}>
        <Flex justify="space-between" align="center">
          <Flex justify="center" align="center" vertical>
            <DisplayText weight="medium" value={`${wonPercent}%`} color={styles.primary} size="section-title" />
            <DisplayText weight="medium" value="Won" color={styles.primary} size="section-title" />
          </Flex>

          <Container height={170}>
            <DoughnutChart />
          </Container>

          <Flex justify="center" align="center" vertical>
            <DisplayText weight="medium" value={`${lostPercent}%`} color={styles.red} size="section-title" />
            <DisplayText weight="medium" value="Lost" color={styles.red} size="section-title" />
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};
