import React, { FC } from 'react';
import { Container, DisplayText } from '../../../components';
import { Flex } from 'antd';
import { AttackingPlayersTable, PassingPlayersTable, ValuablePlayersTable } from './components';
import { useMatchDetailsService } from '../MatchDetailsService';

export const PlayerStats: FC = () => {
  const { matchPlayers, match } = useMatchDetailsService();
  return (
    <Flex vertical gap={30}>
      <Flex vertical gap={20}>
        <DisplayText value="Valuable Players" size="section-title" weight="semi-bold" />
        <Flex gap={20}>
          <ValuablePlayersTable
            data={matchPlayers.homeValuablePlayers}
            team={match.match.home.name}
            avatar={match.match.home.logoUrl}
          />
          <ValuablePlayersTable
            data={matchPlayers.awayValuablePlayers}
            team={match.match.away.name}
            avatar={match.match.away.logoUrl}
          />
        </Flex>
      </Flex>

      {/* <Flex vertical gap={20}>
        <DisplayText value="Team Streaks With Player" size="section-title" weight="semi-bold" />
        <Flex gap={15}>
          <TeamStreaks />
          <TeamStreaks />
        </Flex>
      </Flex> */}

      <Flex vertical gap={20}>
        <DisplayText value="Valuable Attacking Players" size="section-title" weight="semi-bold" />
        <Container bordered innerSpace={15}>
          <Flex vertical gap={10}>
            <AttackingPlayersTable
              players={matchPlayers.homeAttackingPlayers}
              team={match.match.home.name}
              avatar={match.match.home.logoUrl}
            />
            <AttackingPlayersTable
              players={matchPlayers.awayAttackingPlayers}
              team={match.match.away.name}
              avatar={match.match.away.logoUrl}
            />
          </Flex>
        </Container>
      </Flex>

      <Flex vertical gap={20}>
        <DisplayText value="Valuable Passing Players" size="section-title" weight="semi-bold" />
        <Container bordered innerSpace={15}>
          <Flex vertical gap={10}>
            <PassingPlayersTable
              players={matchPlayers.homePassingPlayers}
              team={match.match.home.name}
              avatar={match.match.home.logoUrl}
            />
            <PassingPlayersTable
              players={matchPlayers.awayPassingPlayers}
              team={match.match.away.name}
              avatar={match.match.away.logoUrl}
            />
          </Flex>
        </Container>
      </Flex>
    </Flex>
  );
};
