import React, { FC, PropsWithChildren } from 'react';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BetGroupContainer.scss';

export interface Props {
  header: string;
  collapsed: boolean;
}

export const BetGroupContainer: FC<PropsWithChildren<Props>> = ({ header, collapsed, children }) => {
  const [expand, setExpand] = React.useState<boolean>(collapsed);
  return (
    <div className="bet-group-container">
      <div className="bet-group-container-header">
        <div>
          <p>{header}</p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={expand ? faMinus : faPlus}
            style={{ color: '#00be8c', width: '20px', height: '20px' }}
            onClick={() => setExpand(!expand)}
          />
        </div>
      </div>
      <div className={expand ? '' : 'bet-group-container-hidden'}>{children}</div>
    </div>
  );
};
