import React, { FC } from 'react';
import { useBetsListService } from './BetsListService';
import { Container, Loading, BetCard } from '../../components';
import { BetsFilters } from './BetsFilters';
import { Flex } from 'antd';
import { NoItems, Space } from '../../components';

export const BetsScreen: FC = () => {
  const { bets, loading } = useBetsListService();
  return (
    <Loading loading={loading}>
      <Space size={20} />
      <Flex justify="center">
        <Container width={825}>
          <BetsFilters />

          <Space size={20} />

          <Flex vertical gap={20}>
            {bets?.map(b => <BetCard key={b.id} bet={b} />)}
          </Flex>
          {bets?.length === 0 && (
            <>
              <Space size={40} />
              <NoItems value="No Bets" width={825} />
            </>
          )}
        </Container>
      </Flex>
      <Space size={30} />
    </Loading>
  );
};
