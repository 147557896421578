import React, { FC } from 'react';
import { HeadToHeadMatch, Team } from '../../../models';
import { Avatar, Flex } from 'antd';
import { Container, DisplayText, DisplayGoals, ResultBadge, Space, Table } from '../../../components';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

export interface LastMatchesListProps {
  matches: HeadToHeadMatch[];
  team: Team;
}

const LastMatchesList: FC<LastMatchesListProps> = ({ matches, team }) => {
  const navigate = useNavigate();

  const columns: ColumnsType<HeadToHeadMatch> = [
    {
      key: 'result',
      render: (row: HeadToHeadMatch) => <ResultBadge match={row} team={team} />,
    },
    {
      key: 'teams',
      render: (row: HeadToHeadMatch) => (
        <Container width={200}>
          <Flex vertical gap={5} flex={1}>
            <Flex gap={5}>
              <Avatar size={23} src={row.home.logoUrl} />
              <DisplayText value={row.home.name} overflow />
            </Flex>
            <Flex gap={5} flex={1}>
              <Avatar size={23} src={row.away.logoUrl} />
              <DisplayText value={row.away.name} overflow />
            </Flex>
          </Flex>
        </Container>
      ),
    },
    {
      key: 'goals',
      render: (row: HeadToHeadMatch) => (
        <Flex vertical gap={5} align="center">
          <DisplayGoals value={row.homeGoals} isHomeTeam />
          <DisplayGoals value={row.awayGoals} />
        </Flex>
      ),
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Container height={50}>
        <Flex align="center" justify="center">
          <Avatar size={50} src={team.logoUrl} />
        </Flex>
      </Container>

      <Space size={5} />

      <Table
        columns={columns}
        data={matches}
        pagination={false}
        showHeader={false}
        rowKey="id"
        cellPadding={6}
        onRow={(x: HeadToHeadMatch) => ({
          onClick: () => {
            navigate(`/matches/${x.id}`);
            window.scrollTo(0, 0);
          },
        })}
      />
    </Container>
  );
};

export default LastMatchesList;
