import React, { FC } from 'react';
import { useProfileService } from '../ProfileService';
import { Container, DisplayDecimal, DisplayPercentage, DisplayText, Table } from '../../../components';
import { LeagueStatistics, MonthlyUserStatistics } from '../../../models';
import { ColumnsType } from 'antd/es/table';
import { styles } from '../../../styles/styles';
import { Avatar, Flex } from 'antd';

export const LeaguePerformance: FC = () => {
  const { userStats, leagues } = useProfileService();
  const data = userStats.leagueBetStats.filter(x => x.stats.lost + x.stats.won > 0);

  const columns: ColumnsType<LeagueStatistics> = [
    {
      key: 'league',
      title: 'League',
      dataIndex: 'leagueId',
      align: 'center',
      width: 200,
      render: (leagueId: string) => (
        <Flex gap={5} align='center' justify='center'>
          <Avatar size={25} src={leagues.find(x => x.id === leagueId).countryFlag} />
          <DisplayText value={leagues.find(x => x.id === leagueId).name} weight="medium" />
        </Flex>
      ),
    },
    {
      key: 'yield',
      title: 'Yield',
      align: 'center',
      render: (row: MonthlyUserStatistics) => (
        <DisplayPercentage
          value={row.stats.yield}
          weight="medium"
          color={row.stats.yield >= 0 ? styles.primary : styles.red}
          textAlign='center'
        />
      ),
    },
    {
      key: 'bets',
      title: 'Bets',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: MonthlyUserStatistics) => (
        <DisplayText value={won + pending + lost} weight="medium" textAlign='center' />
      ),
    },
    {
      key: 'wld',
      title: 'W/L/D',
      align: 'center',
      render: ({ stats: { won, pending, lost } }: MonthlyUserStatistics) => (
        <Flex align="center" justify="center">
          <DisplayText value={`${won}/`} weight="medium" color={styles.primary} />
          <DisplayText value={`${pending}/`} weight="medium" color={styles.yellow} />
          <DisplayText value={lost} weight="medium" color={styles.red} />
        </Flex>
      ),
    },
    {
      key: 'averageOdd',
      title: 'Average Odd',
      align: 'center',
      render: ({ stats: { averageOdd } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={averageOdd} weight="medium" textAlign='center' />
      ),
    },
    {
      key: 'averageBet',
      title: 'Average Bet',
      align: 'center',
      render: ({ stats: { averageBet } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={averageBet} weight="medium" textAlign='center' showCurrency />
      ),
    },
    {
      key: 'roi',
      title: 'ROI',
      align: 'center',
      render: ({ stats: { roi } }: MonthlyUserStatistics) => (
        <DisplayDecimal 
          value={roi}
          weight="medium"
          color={roi >= 0 ? styles.primary : styles.red}
          textAlign='center'
          showCurrency
        />
      ),
    },
    {
      key: 'passage',
      title: 'Passage',
      align: 'center',
      render: ({ stats: { won, lost } }: MonthlyUserStatistics) => (
        <DisplayDecimal value={won / (won + lost)} weight="medium" textAlign='center' />
      ),
    },
  ];

  return (
    <Container bordered innerSpace={20}>
      <Table showHeader pagination={false} columns={columns} data={data} rowKey="leagueId" />
    </Container>
  );
};
