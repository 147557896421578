import React, { FC } from 'react';
import { styles } from '../../../../styles/styles';
import { styled } from 'styled-components';
import { DisplayText, Table, UserAvatar } from '../../../../components';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Flex } from 'antd';
import { Status } from './index';

interface ColumnsPlayer {
  name: string;
  averageRatting: string;
  minutesPlayed: string;
  averagePoints: string;
  goals: string;
  assists: string;
  shots: string;
  keyPasses: string;
  availability: boolean;
}

const columns: ColumnsType<ColumnsPlayer> = [
  {
    key: 'name',
    title: '',
    dataIndex: 'name',
    align: 'center',
    render: (name: string) => (
      <Flex gap={10} align="center">
        <UserAvatar size={30} />
        <DisplayText value={name} />
      </Flex>
    ),
  },
  {
    key: 'averageRatting',
    title: 'Average Ratting (5 games)',
    dataIndex: 'averageRatting',
    align: 'center',
    render: (averageRatting: string) => <DisplayText value={averageRatting} />,
  },
  {
    key: 'minutesPlayed',
    title: 'Minutes Played',
    dataIndex: 'minutesPlayed',
    align: 'center',
    render: (minutesPlayed: string) => <DisplayText value={minutesPlayed} />,
  },
  {
    key: 'averagePoints',
    title: 'Average Points With/Without Player',
    dataIndex: 'averagePoints',
    align: 'center',
    render: (averagePoints: string) => <DisplayText value={averagePoints} />,
  },
  {
    key: 'goals',
    title: 'Goals(P)',
    dataIndex: 'goals',
    align: 'center',
    render: (goals: string) => <DisplayText value={goals} />,
  },
  {
    key: 'assists',
    title: 'Assists',
    dataIndex: 'assists',
    align: 'center',
    render: (assists: string) => <DisplayText value={assists} />,
  },
  {
    key: 'shots',
    title: 'Shots Per Game',
    dataIndex: 'shots',
    align: 'center',
    render: (shots: string) => <DisplayText value={shots} />,
  },
  {
    key: 'keyPasses',
    title: 'Key Passes',
    dataIndex: 'keyPasses',
    align: 'center',
    render: (keyPasses: string) => <DisplayText value={keyPasses} />,
  },
  {
    key: 'availability',
    title: 'Availability',
    dataIndex: 'availability',
    align: 'center',
    render: (availability: boolean) => <Status status={availability} />,
  },
];

const data = [
  {
    name: 'Ryan Fredericks',
    averageRatting: '7.13',
    minutesPlayed: '1570',
    averagePoints: '2.34/1.56',
    goals: '12',
    assists: '2',
    shots: '2',
    keyPasses: '2',
    availability: true,
  },
  {
    name: 'Ryan',
    averageRatting: '7.13',
    minutesPlayed: '1570',
    averagePoints: '2.34/1.56',
    goals: '12',
    assists: '2',
    shots: '2',
    keyPasses: '2',
    availability: false,
  },
  {
    name: 'Ryan Fredericks Fredericks',
    averageRatting: '7.13',
    minutesPlayed: '1570',
    averagePoints: '2.34/1.56',
    goals: '12',
    assists: '2',
    shots: '2',
    keyPasses: '2',
    availability: true,
  },
];

export const TableTemplate: FC = () => {
  return (
    <Flex vertical gap={10}>
      <TeamHeader>
        <Flex gap={10} justify="center" align="center">
          <Avatar size={30} />
          <DisplayText value="Everton" />
        </Flex>
      </TeamHeader>
      <Table showHeader pagination={false} columns={columns} data={data} rowKey={'id'} cellPadding={8} />
    </Flex>
  );
};

const TeamHeader = styled.div`
  width: 100%;
  height: 45px;
  background-color: ${styles.primaryLight};
  border-radius: 10px;
  padding-top: 7px;
`;
