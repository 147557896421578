import React, { FC } from 'react';
import { Button, Layout, Menu, Image, Typography, Flex, Space } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserOutlined, TrophyOutlined } from '@ant-design/icons';
import style from './Header.module.scss';
import Logo from '../assets/img/Logo.svg';
import { Clickable, UserAvatar, useLoginModalService, useSignUpModalService } from '../components';
import { useHeaderService } from './HeaderService';

type MenuProps = {
  label: JSX.Element;
  key: string;
  icon: JSX.Element;
  style: string;
};

const items: MenuProps[] = [
  {
    label: <Link to={'/matches'}>Matches</Link>,
    key: 'matches',
    icon: <TrophyOutlined />,
    style: `${style.MenuItem} ${style.MenuItemBorder} `,
  },
  {
    label: <Link to={'/bets'}>Bets</Link>,
    key: 'bets',
    icon: <UserOutlined />,
    style: `${style.MenuItem}`,
  },
];

const getSelectedKey = (path: string) => {
  return path === '/matches' ? 'matches' : path === '/bets' ? 'bets' : '';
};

const Header: FC = () => {
  const { show: showLogin } = useLoginModalService();
  const { show: showSignup } = useSignUpModalService();
  const { Text } = Typography;
  const { user } = useHeaderService();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState(getSelectedKey(location.pathname));

  React.useEffect(() => {
    setSelectedKey(getSelectedKey(location.pathname));
  }, [location.pathname]);

  const redirectToProfile = (id: string) => {
    navigate(`/users/${id}`);
  };

  return (
    <Layout className={style.Container}>
      <Space className={style.Logo}>
        <Link to="/">
          <Image src={Logo} alt="Logo" preview={false} />
        </Link>

        <Menu className={style.Menu} selectedKeys={[selectedKey]} mode="horizontal">
          {items.map(item => (
            <Menu.Item key={item.key} icon={item.icon} className={item.style}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Space>
      <Space className={style.Auth}>
        {user ? (
          <Clickable onClick={() => redirectToProfile(user.id)}>
            <Flex>
              <UserAvatar size={30} />
              <Text className={style.AccountText}>{user.balance}$</Text>
              <Text className={style.AccountText}>{user.id}</Text>
            </Flex>
          </Clickable>
        ) : (
          <Space>
            <Flex>
              <Button onClick={showLogin} type="primary" className={`${style.Button} ${style.SignIn}`}>
                Sign In
              </Button>
              <Button onClick={showSignup} type="primary" className={`${style.Button} ${style.SignUp}`}>
                Sign Up
              </Button>
            </Flex>
          </Space>
        )}
      </Space>
    </Layout>
  );
};

export default Header;
