import React, { FC } from 'react';
import { Table as AntdTable } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { styled } from 'styled-components';
import { styles } from '../styles/styles';
import { GetRowKey } from 'antd/es/table/interface';
import { NoItems } from './NoItems';

interface Props {
  columns: ColumnsType<any>;
  data: any[];
  showHeader: boolean;
  pagination: false | TablePaginationConfig;
  rowKey: PropertyKey | GetRowKey<any>;
  noDataText?: string;
  cellPadding?: number;
  onRow?: (value: any, index: number) => React.HTMLAttributes<any>;
  rowHighlight?: (value: any) => boolean;
  showVerticalBorders?: boolean;
}

export const Table: FC<Props> = ({
  columns,
  showHeader,
  data,
  pagination,
  rowKey,
  noDataText = 'No data',
  cellPadding = 16,
  onRow,
  rowHighlight,
  showVerticalBorders = false,
}) => {
  const onRowClassName = (record: any) => {
    if (rowHighlight && rowHighlight(record)) {
      return 'highlighted-row';
    }

    return '';
  };

  if (!data) {
    return <NoItems value={noDataText} />;
  }

  return (
    <StyledTable
      rowKey={rowKey}
      showHeader={showHeader}
      pagination={pagination}
      columns={columns}
      dataSource={data}
      rowClassName={onRowClassName}
      onRow={onRow}
      $cellPadding={cellPadding}
      $showVerticalBorders={showVerticalBorders}
    />
  );
};

export const StyledTable = styled(AntdTable)<{ $cellPadding: number; $showVerticalBorders }>`
  tbody {
    tr:last-child {
      td {
        border-bottom: none !important;
      }
    }

    tr {
      td:last-child {
        border-right: none !important;
      }

      td:first-child {
        border-bottom: 3px solid ${styles.white};
      }

      &:hover {
        cursor: pointer;
        background: none;

        td {
          border-bottom: 3px solid ${styles.primary};
        }
      }

      td {
        white-space: nowrap;
        border-bottom: 1px solid ${styles.borderColor} !important;
        padding: ${props => props.$cellPadding}px !important;
        border-right: ${props => props.$showVerticalBorders && `1px solid ${styles.borderColor} !important`};
      }
    }
  }

  thead {
    tr,
    th {
      border-bottom: none !important;
      padding: ${props => props.$cellPadding}px !important;
    }
  }

  .highlighted-row {
    td {
      background-color: ${styles.primaryLight};
    }

    td:first-child {
      border-left: 3px solid ${styles.primaryLight};
    }
  }
`;
