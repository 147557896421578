import React, { FC } from 'react';
import { Container, DisplayDecimal, DisplayPercentage, DisplayText, Space } from '../../../components';
import { Flex } from 'antd';
import { useProfileService } from '../ProfileService';
import { styles } from '../../../styles/styles';

export const ProfitStatistics: FC = () => {
  const { userStats } = useProfileService();
  const stat = userStats.monthBetStats.length > 0 ? userStats.monthBetStats[0].stats : { yield: 0, roi: 0, maxStreak: 0, maxProfit: 0};

  return (
    <Flex>
      <Container width={400} height={210} bordered innerSpace={20}>
        <Space size={30} />

        <Flex justify="space-around" align='center' >
          <Flex vertical align="center">
            <DisplayText weight="medium" size="section-title" value="YIELD" />
            <DisplayPercentage
              value={stat.yield}
              color={styles.primary}
              size="extra"
              weight="medium"
            />
          </Flex>

          <Flex vertical align="center">
            <DisplayText weight="medium" size="section-title" value="ROI" />
            <DisplayDecimal
              value={stat.roi}
              color={styles.primary}
              size="extra"
              weight="medium"
              showCurrency
            />
          </Flex>
        </Flex>

        <Space size={40} />

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Streak" />
          <DisplayText weight="medium" size="normal" value={stat.maxStreak} />
        </Flex>

        <Flex justify="space-between">
          <DisplayText weight="medium" size="normal" value="Maximum Winnings" />
          <DisplayDecimal weight="medium" size="normal" value={stat.maxProfit} showCurrency />
        </Flex>
      </Container>
    </Flex>
  );
};
