import { useMemo } from 'react';
import { create } from 'zustand';
import { MatchesGridConfig } from '../../models';
import dayjs from 'dayjs';
import { useLeaguesApi, useMatchesApi, usePredictionsApi } from '../../api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface State {
  config: MatchesGridConfig;
  setConfig(config: MatchesGridConfig): void;
}

const useStore = create<State>()(set => ({
  config: {
    leagueIds: [],
    date: dayjs().toString(),
    status: 'all',
  },
  setConfig: config => set(state => ({ ...state, config })),
}));

export function useMatchesListService() {
  const queryClient = useQueryClient();
  const { getMatches } = useMatchesApi();
  const { getRecommended } = usePredictionsApi();
  const { getLeagues, updateFavouriteLeague: update } = useLeaguesApi();
  const { config, setConfig } = useStore();

  const matches = useQuery({
    queryKey: ['matches', config],
    queryFn: () => getMatches(config.leagueIds, config.date, 1),
  });

  const leagues = useQuery({
    queryKey: ['leagues'],
    queryFn: () => getLeagues(true),
  });

  const recommended = useQuery({
    queryKey: ['recommended'],
    queryFn: () => getRecommended(),
  });

  const updateFavouriteLeague = (id: string, favourite: boolean) => {
    update(id, favourite).then(() =>
      queryClient.invalidateQueries({
        queryKey: ['leagues'],
        refetchType: 'all',
      }),
    );
  };

  const filteredMatches = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return matches.data?.filter(m => {
      switch (config.status) {
        case 'all':
          return true;
        case 'finished':
          return m.homeGoals != null && m.awayGoals != null;
        case 'upcoming':
          return m.homeGoals == null && m.awayGoals == null;
      }
    });
  }, [config.status, matches.data]);

  const leagueMatches = useMemo(() => {
    return leagues.data
      ?.map(l => ({
        ...l,
        matches: matches.data?.filter(x => x.leagueId === l.id) ?? [],
      }))
      .filter(x => x.matches.length)
      .sort((a, b) => {
        if (+b.favourite !== +a.favourite) return +b.favourite - +a.favourite;

        if (a.countryCode !== b.countryCode) {
          return a.countryCode < b.countryCode ? -1 : 1;
        }

        return a.name < b.name ? -1 : 1;
      });
  }, [leagues.data, matches.data]);

  return {
    matches: filteredMatches,
    recommended: recommended.data,
    leagues: leagues.data,
    leagueMatches,
    loading: matches.isFetching || leagues.isFetching || recommended.isFetching,
    config,
    setConfig,
    updateFavouriteLeague,
  };
}
