import { create } from 'zustand';
import { useAuthApi } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useCognito } from '../../auth';
import { LoginRequest } from '../../models';
import { useConfirmCodeModalService } from '../ConfirmCodeModal';

interface State {
  display: boolean;
  setDisplay(display: boolean): void;
}

const useStore = create<State>()(set => ({
  display: false,
  setDisplay: display => set(state => ({ ...state, display })),
}));

export function useLoginModalService() {
  const store = useStore();
  const { setCurrentUser, setUsername } = useCognito();
  const { login: authLogin } = useAuthApi();
  const { show: showConfirmCode } = useConfirmCodeModalService();

  const loginResult = useMutation({
    mutationFn: (req: LoginRequest) => authLogin(req),
    onSuccess: (user: CognitoUser) => {
      setCurrentUser();
      hide();
    },
    onError: (error: any) => {
      if (error.code === 'UserNotConfirmedException') {
        hide();
        showConfirmCode();
      }
    },
  });

  const login = (req: LoginRequest) => {
    loginResult.mutate(req);
    setUsername(req.username);
  };

  const show = () => {
    store.setDisplay(true);
  };

  const hide = () => {
    store.setDisplay(false);
  };

  return {
    display: store.display,
    hide,
    show,
    login,
  };
}
