import React, { FC } from 'react';
import { styles } from '../styles/styles';
import { styled } from 'styled-components';
import { fontWeights, fontSizes, FontSizes, FontWeights } from '../utils';

interface Props {
  size?: FontSizes;
  value: string | number;
  color?: string;
  weight?: FontWeights;
  overflow?: boolean;
  uppercase?: boolean;
  textAlign?: 'start' | 'center' | 'end';
  fullWidth?: boolean;
}

export const DisplayText: FC<Props> = ({
  value,
  size = 'small',
  color = styles.black,
  weight = 'normal',
  overflow = false,
  uppercase = false,
  textAlign = 'start',
  fullWidth = false,
}) => (
  <StyledSpan
    $color={color}
    $size={fontSizes[size]}
    $weight={fontWeights[weight]}
    $overflow={overflow}
    $uppercase={uppercase}
    $textAlign={textAlign}
    $fullWidth={fullWidth}>
    {value}
  </StyledSpan>
);

const StyledSpan = styled.div<{
  $color: string;
  $size: string;
  $weight: number;
  $overflow: boolean;
  $uppercase: boolean;
  $textAlign: string;
  $fullWidth: boolean;
}>`
  text-align: ${props => props.$textAlign};
  text-wrap: balance;
  color: ${props => props.$color};
  font-size: ${props => props.$size};
  font-weight: ${props => props.$weight};
  ${props =>
    props.$overflow &&
    `
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${props =>
    props.$uppercase &&
    `
    text-transform: uppercase;
  `}

  ${props =>
    props.$fullWidth &&
    `
    width: 100%
  `}
`;
