import React, { FC } from 'react';
import { Position, Team } from '../../../models';
import { useMatchDetailsService } from '../MatchDetailsService';
import { ColumnsType } from 'antd/es/table';
import { Container, DisplayText, Table } from '../../../components';

const TournamentPreview: FC = () => {
  const {
    match: {
      match,
      tournament: { positions },
    },
  } = useMatchDetailsService();

  const columns: ColumnsType<Position> = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      align: 'center',
      render: (id: string) => <DisplayText value={id} textAlign='center' />,
    },
    {
      key: 'team',
      title: 'Team',
      dataIndex: 'team',
      align: 'center',
      render: (team: Team) => (
        <Container width={170}>
          <DisplayText value={team.name} overflow textAlign='center'/>
        </Container>
      ),
    },
    {
      key: 'matches',
      title: 'M',
      dataIndex: 'games',
      align: 'center',
      render: (games: number) => <DisplayText value={games} textAlign='center' />,
    },
    {
      key: 'points',
      title: 'P',
      dataIndex: 'points',
      align: 'center',
      render: (points: number) => <DisplayText value={points} textAlign='center' />,
    },
  ];

  return (
    <Container bordered innerSpace={15}>
      <Table
        showHeader
        pagination={false}
        rowHighlight={(p: Position) => p.teamId === match.home.id || p.teamId === match.away.id}
        columns={columns}
        data={positions}
        rowKey={'id'}
        cellPadding={4}
      />
    </Container>
  );
};

export default TournamentPreview;
